import React from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import { StaticImage } from "gatsby-plugin-image"

export default class Slider extends React.Component {
  
  
    render() {

        return (
<div className="slider" style={{ position:'relative', bottom:50 }}>
            <Container>
            <Row>
              <Col>
              <Carousel fade indicators={false}>
            <Carousel.Item>
            <StaticImage
                src="../images/slider/slider1.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
              />
            </Carousel.Item>
            <Carousel.Item>
            <StaticImage
                src="../images/slider/slider2.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
              />
            </Carousel.Item>
            <Carousel.Item>
            <StaticImage
                src="../images/slider/slider3.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                
              />
            </Carousel.Item>
          
            <Carousel.Item>
            <StaticImage
                src="../images/slider/slider4.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b5.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b6.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b7.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b8.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b9.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b10.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b11.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b12.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b13.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b14.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b15.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b16.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b17.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b18.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b19.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b20.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b21.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b22.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b23.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b24.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b25.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b26.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b27.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b28.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b29.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b30.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b31.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

            <Carousel.Item>
            <StaticImage
                src="../images/slider/b32.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                layout={'fullWidth'}
              />
            </Carousel.Item>

          
          </Carousel>
              </Col>
            </Row>
            
          </Container>
</div>




            );
        }
      }