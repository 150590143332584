import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Box, Typography } from '@material-ui/core';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

import { StaticImage } from "gatsby-plugin-image"
// import { Card } from 'antd';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

import Slider from "../components/slider"
import Download from "../components/download"


import { Container, Row, Col, Card, Button } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);


const IndexPage = () => {
return(
  <Layout>
    <Seo title="3 years and 5 years LLB Course" description="Dr.B.R Ambedkar College Of Law Bangalore, Karnataka offers 3 years and 5 years LLB Course" />

    <ThemeProvider theme={theme}>
<Slider/>

<Container>
  <Row className="justify-content-md-center">
    <Col xs={12} md={6} lg={4} className="d-flex">
    <Card className="shadow mb-2 bg-light flex-fill">
  <Card.Header className="bg-success text-white">
    <Typography variant="h6" align="center" style={{ color:'#fff' }}> College</Typography>
    </Card.Header>
  <Card.Body>
    <Box align="center">
  <StaticImage
      src="../images/college.jpg"
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="college"
    />
    </Box>
 
  </Card.Body>
</Card>
    </Col>

    <Col xs={12} md={6} lg={4} className="d-flex">
    <Card className="shadow mb-2 bg-light flex-fill">
  <Card.Header className="bg-danger text-white">
  <Typography variant="h6" align="center" style={{ color:'#fff' }}>Latest News And Events</Typography>
  </Card.Header>
  <Card.Body>


<div className="marquee" style={{ height: 150 }}>
  <div>
  <Typography variant="body2" paragraph><a href="https://pdf.drbrambedkarcollegeoflaw.com/pdf/year.pdf" target="_blank" rel="noopener noreferrer"><DoubleArrowIcon style={{ fontSize:22 }}/> Admission open for LL.B., 3 years &amp; 5 years</a></Typography>
    
                           <Typography variant="body2" paragraph><a href="https://drbrambedkarcollegeoflawbengaluru.wordpress.com" target="_blank" rel="noopener noreferrer"><DoubleArrowIcon style={{ fontSize:22 }}/> College blog</a></Typography>
  </div>
</div>



  <Download/>
  


  </Card.Body>
</Card>
    </Col>

    <Col xs={12} md={6} lg={4} className="d-flex">
    <Card className="shadow mb-2 bg-light flex-fill">
  <Card.Header className="bg-primary text-white">
  <Typography variant="h6" align="center" style={{ color:'#fff' }}>Principal</Typography>
  </Card.Header>
  <Card.Body>
    <Box align="center">
  <StaticImage
      src="../images/principle.jpg"
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="Principal"
    />
    </Box>
    <Card.Title className="text-center mt-2">Prof.C.Ramamurthy.</Card.Title>
    <Card.Text className="text-center mt-2">
    M.A.,LL.B.,M.L.,M.Phil.,Ph.D.
    </Card.Text>
    <Box align="center">
      <Link to="/pricipal-desk/">
    <Button variant="dark">Principal Message</Button>
    </Link>
    </Box>
  </Card.Body>
</Card>
    </Col>

    
  </Row>
</Container>
</ThemeProvider>
  </Layout>
)
  }

export default IndexPage
